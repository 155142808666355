<template>
  <div class="flex items-center justify-center">
    <div class="sm:w-max-2xl w-full">
      <vs-table class="md:w-full relative overflow-auto" ref="table">
        <template #notFound> You don't have any subscriptions yet. </template>
        <template #thead class="w-full">
          <vs-tr>
            <vs-th class="!w-2">
              <i class="bx bx-check" />
            </vs-th>
            <vs-th> Game</vs-th>
            <vs-th> User</vs-th>
            <vs-th class="!w-1/3"> Key</vs-th>
            <vs-th> Date </vs-th>
          </vs-tr>
        </template>
        <template class="w-full" #tbody v-if="subscriptions">
          <vs-tr :key="i" v-for="(tr, i) in subscriptions.docs" :data="tr">
            <vs-td>
              <i v-if="checkExpired(tr)" class="bx bx-x"></i>
              <i v-else class="bx bx-check"></i>
            </vs-td>
            <vs-td class="w-10">
              {{ tr.game && tr.game.shortName }}
            </vs-td>
            <vs-td>
              {{ tr.subscriber.username }}
            </vs-td>
            <vs-td> {{ tr.key }}</vs-td>
            <vs-td> {{ tr.expiredAt | formatTimestamp }}</vs-td>
          </vs-tr>
        </template>
        <template #footer v-if="subscriptions">
          <vs-pagination v-model="page" :length="subscriptions.totalPages" />
        </template>
      </vs-table>
    </div>
  </div>
</template>

<script>
import { getAllSubscriptions } from "@/services/ApiService";

export default {
  name: "SubscriptionsTable",
  data: () => ({
    subscriptions: [],
    page: 1,
    max: 10,
  }),
  methods: {
    async getSubscriptions() {
      const loading = this.$vs.loading({
        target: this.$refs.table,
        background: "rgba(0, 0, 0, 0.6)",
        color: "primary",
        type: "points",
      });
      this.subscriptions = await getAllSubscriptions(this.page)
        .then((response) => response)
        .catch(() => {
          this.$vs.notification({
            color: "danger",
            name: "Error",
            text: "Something went wrong!",
          });
          loading.close();
        });
      loading.close();
    },
    checkExpired(subscription) {
      return subscription.expiredAt * 1000 <= Date.now();
    },
  },
  async mounted() {
    await this.getSubscriptions();
  },
  watch: {
    page() {
      this.getSubscriptions();
    },
  },
};
</script>
